import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import BookStepsLayout from '../../app/common/BookStepsLayout';
import PompaProgressBar from '../PompaProgressBar/PompaProgressBar';
import { getUrl } from '../../app/api/agent';
import useCookies from '../../hooks/useCookies';
import * as Sentry from '@sentry/react';

const TermsSigns = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { getCookie, setCookie } = useCookies();
  const dealId = searchParams.get('dealId');
  const TEST_PIXEL_ID = '2913991865434847';
  const userId = searchParams.get('userId');

  document
    .querySelector('body')
    .addEventListener('UTMSimpleLoaded', (event) => {
      console.log('drutm data:', window.HandL.getAll());
    });

  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(null);
  const [firedEvent, setFiredEvent] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    address: '',
    zip: '',
    phone: '',
    state: '',
    contacts: [],
  });
  const [errors, setErrors] = useState({
    contacts: [],
  });

  // New states for confirmation & submission disabling
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiUrl = `https://api.pompaprogram.com/associated-contacts?id=${dealId}${
          userId ? `&userId=${userId}` : ''
        }`;
        const response = await axios.get(apiUrl);
        setFormData({
          firstName: response.data.mainContact.properties.firstname || '',
          lastName: response.data.mainContact.properties.lastname || '',
          address: response.data.mainContact.properties.address || '',
          zip: response.data.mainContact.properties.zip || '',
          phone: response.data.mainContact.properties.phone || '',
          state: response.data.mainContact.properties.state || '',
          city: response.data.mainContact.properties.city || '',
          contacts: response.data.contacts || [],
          email: response.data.email || '',
          contactId: response.data.mainContact.id,
        });

        const isVerified = response.data.hasOwnProperty('addressVerified')
          ? response.data.addressVerified
          : false;
        console.log('Verified Flag:', isVerified);
        setFiredEvent(isVerified);
        console.log('Response pixel:', response.data.pixelId);
        const _fbcCookie = getCookie('_fbc');
        console.log('_fbc Cookie:', _fbcCookie);

        if (!_fbcCookie) {
          if (
            response.data._fbc &&
            response.data._fbc !== 'NA' &&
            response.data._fbc !== 'N/A'
          ) {
            console.log(
              'Setting _fbc Cookie Cookie from DynamoDB:',
              response.data._fbc
            );
            setCookie('_fbc', response.data._fbc, {
              expires: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000),
              path: '/',
              domain: 'pompaworkshop.com',
            });
          } else {
            console.log('No FBC Cookie found in DynamoDB');
          }
        }

        // if (response.data.pixelId === TEST_PIXEL_ID) {
        if ('2913991865434847' === TEST_PIXEL_ID) {
          console.log('Loading pixel...', TEST_PIXEL_ID);
          const trackSimpleConfig = document.createElement('script');
          trackSimpleConfig.innerHTML = `
            fb_pixel_id = '${TEST_PIXEL_ID}';
            handl_custom_params = ['utm_partner'];
            var handl_js = document.createElement("script");
            handl_js.setAttribute("src", "https://track.utmsimple.com/utm.js?license=7d4856e0322e11eca0d731cb18926450");
            document.head.appendChild(handl_js);
          `;
          document.head.appendChild(trackSimpleConfig);
        }
        console.log('Loading pixel');
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
        navigate('/terms-sign-failed');
      }
    };

    if (dealId) fetchData();
  }, [dealId, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (value.trim()) setErrors((prev) => ({ ...prev, [name]: '' }));
  };

  const handleContactChange = (e, index, field) => {
    const { value } = e.target;
    const updatedContacts = [...formData.contacts];
    updatedContacts[index] = {
      ...updatedContacts[index],
      properties: {
        ...updatedContacts[index].properties,
        [field]: value,
      },
    };
    setFormData((prev) => ({ ...prev, contacts: updatedContacts }));

    // Clear error when field is filled
    if (value.trim()) {
      setErrors((prev) => ({
        ...prev,
        contacts: prev.contacts.map((contact, i) =>
          i === index ? { ...contact, [field]: '' } : contact
        ),
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};
    if (!formData.firstName.trim())
      newErrors.firstName = 'First Name is required';
    if (!formData.lastName.trim()) newErrors.lastName = 'Last Name is required';
    if (!formData.address.trim()) newErrors.address = 'Address is required';
    if (!formData.zip.trim()) newErrors.zip = 'Zip is required';
    if (!formData.phone.trim()) newErrors.phone = 'Phone is required';
    if (!formData.state.trim()) newErrors.state = 'State is required';
    if (!formData.city.trim()) newErrors.city = 'City is required';

    // Add contact validation
    const contactErrors = formData.contacts.map((contact) => ({
      firstname: !contact.properties.firstname?.trim()
        ? 'First Name is required'
        : '',
      lastname: !contact.properties.lastname?.trim()
        ? 'Last Name is required'
        : '',
      address: !contact.properties.address?.trim() ? 'Address is required' : '',
      zip: !contact.properties.zip?.trim() ? 'Zip is required' : '',
      phone: !contact.properties.phone?.trim() ? 'Phone is required' : '',
      state: !contact.properties.state?.trim() ? 'State is required' : '',
      city: !contact.properties.city?.trim() ? 'City is required' : '',
    }));

    const hasContactErrors = contactErrors.some((error) =>
      Object.values(error).some((val) => val !== '')
    );

    if (Object.keys(newErrors).length || hasContactErrors) {
      setErrors({ ...newErrors, contacts: contactErrors });
      return;
    }

    setIsSubmitting(true);
    try {
      const apiUrl = getUrl();
      const _fbcCookie = getCookie('_fbc');
      console.log('_fbc Cookie:', _fbcCookie);
      let isFiredEventFlag = firedEvent;
      const handl_event_id = getCookie('first_handlID');
      if (!firedEvent) {
        console.log('Firing Purchase event');
        if (handl_event_id) {
          console.log('Firing Purchase event with eventID', handl_event_id);
          window.HandL.sendFBEvent(
            'Purchase',
            { value: 8000, currency: 'USD' },
            { eventID: 'pp_bp_' + handl_event_id }
          );
        } else {
          console.log('Firing Purchase event without eventID');
          window.HandL.sendFBEvent('Purchase', {
            value: 8000,
            currency: 'USD',
          });
        }
        isFiredEventFlag = true;
        setFiredEvent(true);
        Sentry.captureMessage(
          `drutm Purchase event fired - handleSubmit - Terms Signs ${dealId}`,
          {
            extra: {
              dealId,
              pixelId: TEST_PIXEL_ID,
              contactId: formData.contactId,
              cookies: {
                _fbp: getCookie('_fbp'),
                first_handlID: getCookie('first_handlID'),
                gclid: getCookie('gclid'),
                handlID: getCookie('handlID'),
                handlID_ref: getCookie('handlID_ref'),
                handl_ip: getCookie('handl_ip'),
                handl_url: getCookie('handl_url'),
                _fbc: getCookie('_fbc'),
              },
              userAgent: navigator.userAgent,
              drutm: window.HandL.getAll(),
              timestamp: new Date().toISOString(),
            },
            level: 'warning',
          }
        );
      } else {
        console.log('Purchase event not fired');
        Sentry.captureMessage(
          `drutm Purchase event not fired - handleSubmit - Terms Signs ${dealId}`,
          {
            extra: {
              dealId,
              pixelId: TEST_PIXEL_ID,
              contactId: formData.contactId,
              cookies: {
                _fbp: getCookie('_fbp'),
                first_handlID: getCookie('first_handlID'),
                gclid: getCookie('gclid'),
                handlID: getCookie('handlID'),
                handlID_ref: getCookie('handlID_ref'),
                handl_ip: getCookie('handl_ip'),
                handl_url: getCookie('handl_url'),
                _fbc: getCookie('_fbc'),
              },
              drutm: window.HandL.getAll(),
              userAgent: navigator.userAgent,
              timestamp: new Date().toISOString(),
            },
            level: 'warning',
          }
        );
      }
      await axios.post(`${apiUrl}/submit-terms-signs`, {
        ...formData,
        dealId,
        userAgent: navigator.userAgent,
        _fbp: getCookie('_fbp'),
        first_handlID: getCookie('first_handlID'),
        gclid: getCookie('gclid'),
        handlID: getCookie('handlID'),
        handlID_ref: getCookie('handlID_ref'),
        handl_ip: getCookie('handl_ip'),
        handl_url: getCookie('handl_url'),
        _fbc: _fbcCookie,
        firedEvent: isFiredEventFlag,
        handl_event_id: handl_event_id,
      });
      setMessage('Terms Signs submitted successfully');
      setIsModalOpen(true);
    } catch (error) {
      Sentry.captureMessage(
        `drutm Submit error - handleSubmit - Terms Signs ${dealId}`,
        {
          extra: {
            dealId,
            pixelId: TEST_PIXEL_ID,
            contactId: formData.contactId,
            cookies: {
              _fbp: getCookie('_fbp'),
              first_handlID: getCookie('first_handlID'),
              gclid: getCookie('gclid'),
              handlID: getCookie('handlID'),
              handlID_ref: getCookie('handlID_ref'),
              handl_ip: getCookie('handl_ip'),
              handl_url: getCookie('handl_url'),
              _fbc: getCookie('_fbc'),
            },
            drutm: window.HandL.getAll(),
            userAgent: navigator.userAgent,
            timestamp: new Date().toISOString(),
          },
          level: 'warning',
        }
      );
      console.error('Submit error:', error);
      setIsSubmitting(false);
      setMessage('Error submitting Terms Signs');
    }
  };

  const handleConfirm = async () => {
    setIsSubmitting(true);
    try {
      const apiUrl = getUrl();
      const _fbcCookie = getCookie('_fbc');
      console.log('_fbc Cookie:', _fbcCookie);
      let isFiredEventFlag = firedEvent;
      const handl_event_id = getCookie('first_handlID');
      if (!firedEvent) {
        console.log('Firing Purchase event');
        if (handl_event_id) {
          console.log('Firing Purchase event with eventID', handl_event_id);
          window.HandL.sendFBEvent(
            'Purchase',
            { value: 8000, currency: 'USD' },
            { eventID: 'pp_bp_' + handl_event_id }
          );
        } else {
          console.log('Firing Purchase event without eventID');
          window.HandL.sendFBEvent('Purchase', {
            value: 8000,
            currency: 'USD',
          });
        }
        setFiredEvent(true);
        isFiredEventFlag = true;
        Sentry.captureMessage(
          `drutm Purchase event fired - handleConfirm - Terms Signs ${dealId}`,
          {
            extra: {
              dealId,
              pixelId: TEST_PIXEL_ID,
              contactId: formData.contactId,
              cookies: {
                _fbp: getCookie('_fbp'),
                first_handlID: getCookie('first_handlID'),
                gclid: getCookie('gclid'),
                handlID: getCookie('handlID'),
                handlID_ref: getCookie('handlID_ref'),
                handl_ip: getCookie('handl_ip'),
                handl_url: getCookie('handl_url'),
                _fbc: getCookie('_fbc'),
              },
              drutm: window.HandL.getAll(),
              userAgent: navigator.userAgent,
              timestamp: new Date().toISOString(),
            },
            level: 'warning',
          }
        );
      } else {
        console.log('Purchase event not fired');
        Sentry.captureMessage(
          `drutm Purchase event not fired - handleConfirm - Terms Signs ${dealId}`,
          {
            extra: {
              dealId,
              pixelId: TEST_PIXEL_ID,
              contactId: formData.contactId,
              cookies: {
                _fbp: getCookie('_fbp'),
                first_handlID: getCookie('first_handlID'),
                gclid: getCookie('gclid'),
                handlID: getCookie('handlID'),
                handlID_ref: getCookie('handlID_ref'),
                handl_ip: getCookie('handl_ip'),
                handl_url: getCookie('handl_url'),
                _fbc: getCookie('_fbc'),
              },
              drutm: window.HandL.getAll(),
              userAgent: navigator.userAgent,
              timestamp: new Date().toISOString(),
            },
            level: 'warning',
          }
        );
      }
      await axios.post(`${apiUrl}/submit-terms-signs`, {
        ...formData,
        dealId,
        userAgent: navigator.userAgent,
        _fbp: getCookie('_fbp'),
        first_handlID: getCookie('first_handlID'),
        gclid: getCookie('gclid'),
        handlID: getCookie('handlID'),
        handlID_ref: getCookie('handlID_ref'),
        handl_ip: getCookie('handl_ip'),
        handl_url: getCookie('handl_url'),
        _fbc: _fbcCookie,
        firedEvent: isFiredEventFlag,
        handl_event_id: handl_event_id,
      });
      setMessage('Terms Signs submitted successfully');
      setIsModalOpen(true);
    } catch (error) {
      Sentry.captureMessage(
        `drutm Submit error - handleConfirm - Terms Signs ${dealId}`,
        {
          extra: {
            dealId,
            pixelId: TEST_PIXEL_ID,
            contactId: formData.contactId,
            cookies: {
              _fbp: getCookie('_fbp'),
              first_handlID: getCookie('first_handlID'),
              gclid: getCookie('gclid'),
              handlID: getCookie('handlID'),
              handlID_ref: getCookie('handlID_ref'),
              handl_ip: getCookie('handl_ip'),
              handl_url: getCookie('handl_url'),
              _fbc: getCookie('_fbc'),
            },
            drutm: window.HandL.getAll(),
            userAgent: navigator.userAgent,
            timestamp: new Date().toISOString(),
          },
          level: 'warning',
        }
      );
      console.error('Submit error:', error);
      setIsSubmitting(false);
      setMessage('Error submitting Terms Signs');
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    navigate('/');
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          minHeight: '100vh',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#e0f2fe',
          p: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            backgroundColor: 'background.paper',
            p: 4,
            borderRadius: 2,
            boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
            width: '100%',
            maxWidth: 700,
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: 700, mb: 2 }}>
            LOADING...
          </Typography>
          <PompaProgressBar width="55%" message="Loading..." />
        </Box>
      </Box>
    );
  }

  return (
    <BookStepsLayout>
      <Box sx={{ maxWidth: 700, mx: 'auto', py: 4 }}>
        <Typography
          variant="h4"
          sx={{
            fontSize: '32px',
            fontWeight: '700',
            textAlign: 'center',
            mb: 3,
          }}
        >
          Address Verification
        </Typography>
        <Button
          variant="contained"
          type="button"
          sx={{
            mt: 2,
            mb: 3,
            backgroundColor: '#5ec074',
            width: '300px',
            height: '48px',
            fontSize: '1.1rem',
            fontWeight: 'bold',
            margin: '20px auto',
            display: 'block',
            '&:hover': {
              backgroundColor: '#218838',
            },
          }}
          disabled={isSubmitting}
          onClick={handleConfirm}
        >
          My information is Correct
        </Button>
        <Typography
          variant="h4"
          sx={{
            fontSize: '22px',
            fontWeight: '700',
            textAlign: 'center',
            mb: 3,
          }}
        >
          Primary Purchaser
        </Typography>
        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 3,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              width: '100%',
              maxWidth: 400,
            }}
          >
            <TextField
              label="First Name"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              error={!!errors.firstName}
              helperText={errors.firstName}
              fullWidth
            />
            <TextField
              label="Last Name"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              error={!!errors.lastName}
              helperText={errors.lastName}
              fullWidth
            />
            <TextField
              label="Address"
              name="address"
              value={formData.address}
              onChange={handleChange}
              error={!!errors.address}
              helperText={errors.address}
              fullWidth
            />
            <TextField
              label="City"
              name="city"
              value={formData.city}
              onChange={handleChange}
              error={!!errors.city}
              helperText={errors.city}
              fullWidth
            />
            <TextField
              label="Zip"
              name="zip"
              value={formData.zip}
              onChange={handleChange}
              error={!!errors.zip}
              helperText={errors.zip}
              fullWidth
            />
            <TextField
              label="Phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              error={!!errors.phone}
              helperText={errors.phone}
              fullWidth
            />
            <TextField
              label="State"
              name="state"
              value={formData.state}
              onChange={handleChange}
              error={!!errors.state}
              helperText={errors.state}
              fullWidth
            />
          </Box>
          {formData.contacts
            .filter((contact) => contact.properties.is_additional_member)
            .map((contact, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  width: '100%',
                  maxWidth: 400,
                  p: 2,
                  border: '1px solid',
                  borderColor: 'grey.300',
                  borderRadius: 1,
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: '22px',
                    fontWeight: '700',
                    textAlign: 'center',
                    mb: 3,
                  }}
                >
                  Additional Member #{index + 1}
                </Typography>
                <TextField
                  label="First Name"
                  value={contact.properties.firstname || ''}
                  onChange={(e) => handleContactChange(e, index, 'firstname')}
                  error={!!errors.contacts?.[index]?.firstname}
                  helperText={errors.contacts?.[index]?.firstname}
                  fullWidth
                />
                <TextField
                  label="Last Name"
                  value={contact.properties.lastname || ''}
                  onChange={(e) => handleContactChange(e, index, 'lastname')}
                  error={!!errors.contacts?.[index]?.lastname}
                  helperText={errors.contacts?.[index]?.lastname}
                  fullWidth
                />
                <TextField
                  label="Address"
                  value={contact.properties.address || ''}
                  onChange={(e) => handleContactChange(e, index, 'address')}
                  error={!!errors.contacts?.[index]?.address}
                  helperText={errors.contacts?.[index]?.address}
                  fullWidth
                />
                <TextField
                  label="City"
                  value={contact.properties.city || ''}
                  onChange={(e) => handleContactChange(e, index, 'city')}
                  error={!!errors.contacts?.[index]?.city}
                  helperText={errors.contacts?.[index]?.city}
                  fullWidth
                />
                <TextField
                  label="Zip"
                  value={contact.properties.zip || ''}
                  onChange={(e) => handleContactChange(e, index, 'zip')}
                  error={!!errors.contacts?.[index]?.zip}
                  helperText={errors.contacts?.[index]?.zip}
                  fullWidth
                />
                <TextField
                  label="Phone"
                  value={contact.properties.phone || ''}
                  onChange={(e) => handleContactChange(e, index, 'phone')}
                  error={!!errors.contacts?.[index]?.phone}
                  helperText={errors.contacts?.[index]?.phone}
                  fullWidth
                />
                <TextField
                  label="State"
                  value={contact.properties.state || ''}
                  onChange={(e) => handleContactChange(e, index, 'state')}
                  error={!!errors.contacts?.[index]?.state}
                  helperText={errors.contacts?.[index]?.state}
                  fullWidth
                />
              </Box>
            ))}
          <FormControlLabel
            control={
              <Checkbox
                checked={isConfirmed}
                onChange={(e) => setIsConfirmed(e.target.checked)}
              />
            }
            label="I confirm this information is correct"
          />
          {message && (
            <Typography
              variant="body1"
              sx={{ mt: 2, color: 'success.main', textAlign: 'center' }}
            >
              {message}
            </Typography>
          )}
          <Button
            variant="contained"
            type="submit"
            sx={{ mt: 2 }}
            disabled={!isConfirmed || isSubmitting}
          >
            Submit
          </Button>
        </Box>
      </Box>
      <Dialog
        open={isModalOpen}
        onClose={handleCloseModal}
        PaperProps={{
          sx: { backgroundColor: '#e0f2fe', color: '#000' }, // Bright yellow background with black text
        }}
      >
        <DialogTitle sx={{ backgroundColor: '#e0f2fe', color: 'black' }}>
          <Typography variant="h6" sx={{ fontWeight: 700 }}>
            Submission Successful
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: 'white', color: '#000' }}>
          <Typography>Your form has been submitted successfully.</Typography>
        </DialogContent>
        <DialogActions sx={{ backgroundColor: 'white' }}>
          <Button
            onClick={handleCloseModal}
            sx={{
              backgroundColor: '#79a1b5',
              color: '#fff',
              '&:hover': { backgroundColor: '#28a745' },
            }}
            variant="contained"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </BookStepsLayout>
  );
};

export default TermsSigns;
