import Cookies from 'js-cookie';

/**
 * Custom hook for cookie operations
 * @returns {Object} Cookie related functions
 */
const useCookies = () => {
  /**
   * Get a cookie value by name
   * @param {string} name - The name of the cookie to retrieve
   * @returns {string|null} The cookie value or null if not found
   */
  const getCookie = (name) => {
    return Cookies.get(name) || null;
  };

  /**
   * Get multiple cookies data at once
   * @param {Array} cookieNames - Array of cookie names to retrieve
   * @returns {Object} Object with cookie names as keys and values
   */
  const getCookies = (cookieNames) => {
    const result = {};

    cookieNames.forEach((name) => {
      result[name] = getCookie(name);
    });

    return result;
  };

  /**
   * Set a cookie with name, value and options
   * @param {string} name - The name of the cookie
   * @param {string} value - The value of the cookie
   * @param {Object} options - Cookie options (expires, path, domain, etc.)
   */
  const setCookie = (name, value, options = {}) => {
    Cookies.set(name, value, options);
  };

  /**
   * Remove a cookie by name
   * @param {string} name - The name of the cookie to remove
   * @param {Object} options - Cookie options (path, domain, etc.)
   */
  const removeCookie = (name, options = {}) => {
    Cookies.remove(name, options);
  };

  return {
    getCookie,
    getCookies,
    setCookie,
    removeCookie,
  };
};

export default useCookies;
