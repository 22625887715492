import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import BookStepsLayout from '../../app/common/BookStepsLayout';
import VideoEmbed from '../book-new-call/VideoEmbed';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';
import PompaProgressBar from '../PompaProgressBar/PompaProgressBar';
import { getApiKey, getUrl } from '../../app/api/agent';
import useHotjarUser from '../../hooks/useHotjarUser';
import useCookies from '../../hooks/useCookies';
import Time2Book from '../time-2-book/time-2-book';
import Time2Book2 from '../time-2-book/time-2-book-2';
import CircularProgress from '@mui/material/CircularProgress';

const WhichAppointment = () => {
  const TEST_PIXEL_ID = '2913991865434847';
  const { getCookie, getCookies } = useCookies();
  const [searchParams] = useSearchParams();
  const paramOrderId = decodeURIComponent(searchParams.get('orderId'));
  const paramEmail = decodeURIComponent(
    searchParams.get('Email') || searchParams.get('email')
  );
  const productId = decodeURIComponent(searchParams.get('productId'));
  const [isDouble, setIsDouble] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [magicLinkSent, setMagicLinkSent] = useState(false);
  const [customerData, setCustomerData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });
  const [isLoading, setIsLoading] = useState(true);

  const getPixelCookies = () => {
    return getCookies([
      '_fbc',
      '_fbp',
      'user_agent',
      'handl_ip',
      'gclid',
      'handl_url',
      'handlID',
      'first_handlID',
      'handlID_ref',
      'utm_cart',
      'fb_pixel_id',
    ]);
  };

  const loadPixel = async () => {
    const pixelId = getCookie('fb_pixel_id');
    console.log('pixelId from cookie', pixelId);
    if ('2913991865434847' === TEST_PIXEL_ID) {
      console.log('Loading Test pixel... 2913991865434847');
      const trackSimpleConfig = document.createElement('script');
      trackSimpleConfig.innerHTML = `
        fb_pixel_id = '${TEST_PIXEL_ID}';
        handl_custom_params = ['utm_partner'];
        var handl_js = document.createElement("script");
        handl_js.setAttribute("src", "https://track.utmsimple.com/utm.js?license=7d4856e0322e11eca0d731cb18926450");
        document.head.appendChild(handl_js);
      `;
      document.head.appendChild(trackSimpleConfig);
    }
    console.log('pixelId loaded', pixelId);
    return pixelId;
  };

  useHotjarUser(undefined, {
    email: paramEmail,
    firstName: customerData.firstName,
  });

  useEffect(() => {
    loadPixel();
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.pompaprogram.com/get-samcart-customer?order_id=${paramOrderId}`
        );
        const { customer, order } = response.data;
        setIsLoading(false);

        setCustomerData({
          firstName: customer.first_name,
          lastName: customer.last_name,
          email: customer.email,
          phone: customer.phone,
        });

        if (order.cart_items.length > 1) {
          setIsDouble(true);
        }

        const cookiePixelData = getPixelCookies();
        await axios.post('https://api.pompaprogram.com/autoregisterfb', {
          orderId: paramOrderId,
          email: paramEmail,
          productId: productId !== 'null' ? productId : order.cart_items[0].id,
          pixelId: cookiePixelData['fb_pixel_id'],
          ...cookiePixelData,
        });
      } catch (error) {
        setIsLoading(false);
        console.error('Error fetching customer data:', error);
      }
    };

    const hasBookedAppointment = async () => {
      try {
        const response = await axios.get(
          `https://api.pompaprogram.com/has-booked?email=${paramEmail}`
        );
        setBookings(response.data.bookings);
      } catch (error) {
        console.error('Error fetching booking data:', error);
      }
    };

    fetchData();
    hasBookedAppointment();
  }, []);

  const sendMagicLink = async () => {
    try {
      await axios({
        url: getUrl() + '/magic-link',
        method: 'post',
        data: {
          email: paramEmail,
          redirectTo: 'profile',
        },
        headers: {
          'x-api-key': getApiKey(),
          'Content-Type': 'application/json',
        },
      });
      setMagicLinkSent(true);
    } catch (error) {
      console.log('Error sending magic link', error);
    }
  };

  return (
    <BookStepsLayout backgroundColor="#efe9e5">
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          backgroundColor: '#efe9e5',
          height: '220px',
          textAlign: 'center',
          '@media (max-width: 700px)': {
            height: 'auto',
            paddingBottom: '40px',
          },
        }}
      >
        <Typography
          component="h4"
          variant="h4"
          sx={{
            fontSize: '38px',
            textAlign: 'center',
            fontWeight: '700',
            marginBottom: '20px',
            marginTop: '50px',
            '@media (max-width: 700px)': {
              fontSize: '28px',
              marginTop: '20px',
            },
          }}
        >
          WAIT! YOUR ORDER IS NOT COMPLETE.
        </Typography>
        <Typography
          component="h4"
          variant="h4"
          sx={{
            marginTop: '0 !important',
            fontSize: '30px',
            fontStyle: 'italic',
            color: 'red',
            textAlign: 'center',
            fontWeight: 'bold',
            maxWidth: '1150px',
            width: '100%',
            margin: 'auto',
            '@media (max-width: 700px)': {
              width: '90%',
            },
          }}
        >
          <PompaProgressBar width="55%" message="Almost Complete..." />
        </Typography>
      </Box>
      <Box
        sx={{
          fontSize: '1.25rem',
          fontWeight: '500',
          textAlign: 'center',
          margin: '30px 10% 30px 10%',
          '@media (max-width: 700px)': {
            fontSize: '22px',
          },
        }}
      >
        {' '}
        Step 1: Book your appointment with a health advisor below (to review
        your results). If there are no times that work for you, please book an
        appointment and you will be able to reschedule when we reach out to you
        via text.
        <br />
        <br />
        Step 2: Complete the Neurotoxicity Assessment on the next page you and
        your Health Advisor can be prepared for your call.
      </Box>
      {!isLoading ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'center',
            alignItems: 'center',
            gap: '40px',
            marginBottom: '20px',
          }}
        >
          {isDouble ? (
            <Time2Book2
              decodedName={customerData.firstName}
              decodedEmail={paramEmail}
              decodedLastName={customerData.lastName}
              decodedMobile={customerData.phone}
            />
          ) : (
            <Time2Book
              decodedName={customerData.firstName}
              decodedEmail={paramEmail}
              decodedLastName={customerData.lastName}
              decodedMobile={customerData.phone}
            />
          )}
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '40px',
            textAlign: 'center',
          }}
        >
          <CircularProgress
            size={60}
            thickness={4}
            sx={{
              color: '#f76c6c',
              marginBottom: '20px',
            }}
          />
          <Typography
            variant="h6"
            sx={{
              fontWeight: '500',
              fontSize: '18px',
              marginBottom: '8px',
            }}
          >
            Please wait a moment
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: '#666',
              fontStyle: 'italic',
            }}
          >
            Loading your appointment calendar...
          </Typography>
        </Box>
      )}
      <div
        style={{
          margin: 'auto',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          paddingBottom: '30px',
          paddingTop: '20px',
        }}
      >
        <VideoEmbed />
        <Box mb={2} />
      </div>
      {bookings && bookings.length > 0 && (
        <>
          <Box
            sx={{
              display: 'flex',
            }}
          >
            <Typography
              component="h4"
              variant="h4"
              sx={{
                fontSize: '26px',
                textAlign: 'center',
                fontWeight: 'bold',
                margin: 'auto',
              }}
            >
              <Box>
                <Typography
                  variant="h4"
                  sx={{
                    fontSize: '28px',
                    textAlign: 'center',
                    fontWeight: 'bold',
                  }}
                  gutterBottom
                >
                  Wait! It looks like you have already booked an appointment.
                </Typography>
              </Box>
              <Box>
                {bookings.map((booking) => (
                  <Box key={booking.id} mb={2}>
                    <Typography variant="h6">{booking.subject}</Typography>
                    <Typography variant="body1">
                      <strong>Date and Time:</strong>
                      {new Date(booking.starting_time).toLocaleString()}
                    </Typography>
                    <Typography variant="body1">
                      <strong>Email:</strong> {booking.form_submission.email}
                    </Typography>
                  </Box>
                ))}
              </Box>
              <Box>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: '18px',
                    textAlign: 'center',
                    fontWeight: 'bold',
                  }}
                >
                  An email will be sent to {paramEmail || ''} with a login link
                </Typography>
                <button
                  onClick={() => sendMagicLink()}
                  style={{
                    color: 'blue',
                    fontSize: '18px',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    background: 'none',
                    border: 'none',
                    padding: 0,
                    cursor: 'pointer',
                    textDecoration: 'underline',
                  }}
                >
                  Send Magic Link
                </button>
                {magicLinkSent && (
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: '18px',
                      textAlign: 'center',
                      fontWeight: 'bold',
                      color: 'green',
                    }}
                  >
                    Magic Link sent to {paramEmail || ''}
                  </Typography>
                )}
              </Box>
              <Typography
                variant="h4"
                sx={{
                  fontSize: '24px',
                  textAlign: 'center',
                  fontWeight: 'bold',
                  marginBottom: '20px',
                }}
                gutterBottom
              >
                If you'd still like to book a call, please click one of the
                buttons below:
              </Typography>
            </Typography>
          </Box>
        </>
      )}
    </BookStepsLayout>
  );
};

export default WhichAppointment;
