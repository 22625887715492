import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { GenericScheduleEmbed } from '../../app/common/Embed/GenericScheduleEmbed';
import React, { useEffect, useState } from 'react';
import { getUrl } from '../../app/api/agent';
import useHotjarUser from '../../hooks/useHotjarUser';

const Time2Book2 = (props) => {
  console.log('Time2Book2:props', props);
  const { decodedName, decodedEmail, decodedLastName, decodedMobile } = props;

  useHotjarUser(undefined, {
    firstName: decodedName,
    email: decodedEmail,
  });

  const [toggle, setToggle] = useState(false);
  const [mainIframePage, setMainIframePage] = useState();
  const [mainIframeId, setMainIframeId] = useState();
  const [secondaryIframePage, setSecondaryIframePage] = useState();
  const [secondaryIframeId, setSecondaryIframeId] = useState();

  useEffect(() => {
    const randomNumber = Math.floor(Math.random() * 100);

    fetch(getUrl() + `/split-percentage`)
      .then((response) => response.json())
      .then((data) => {
        const percentage = data.percentage;
        const useMainCalendar = randomNumber <= percentage;
        setMainIframePage(
          useMainCalendar ? 'scheduleoncedouble' : 'additionaltimes2'
        );
        setMainIframeId(
          useMainCalendar
            ? 'SOIDIV_scheduleoncedouble'
            : 'SOIDIV_additionaltimes2'
        );
        setSecondaryIframePage(
          useMainCalendar ? 'additionaltimes2' : 'scheduleoncedouble'
        );
        setSecondaryIframeId(
          useMainCalendar
            ? 'SOIDIV_additionaltimes2'
            : 'SOIDIV_scheduleoncedouble'
        );
      })
      .catch((error) => console.error('Failed to fetch main calendar', error));
  }, []);

  const refreshPage = () => {
    window.location.reload();
  };
  return (
    <div
      style={{
        margin: 'auto',
        alignItems: 'center',
        display: 'flex',
        maxWidth: '900px',
        justifyContent: 'center',
        flexDirection: 'column',
        paddingBottom: '40px',
        paddingTop: '20px',
        width: '100%',
      }}
    >
      <div
        style={{
          display: !toggle ? 'block' : 'none',
          width: '80%',
          height: '548px',
          maxWidth: '900px',
        }}
      >
        {mainIframeId && (
          <GenericScheduleEmbed
            iframeId={mainIframeId}
            iframePage={mainIframePage}
            email={decodedEmail}
            name={decodedName}
            lastName={decodedLastName}
            phone={decodedMobile}
          />
        )}
      </div>
      <div
        style={{
          width: '80%',
          height: '585px',
          maxWidth: '900px',
          display: toggle ? 'block' : 'none',
        }}
      >
        <div
          style={{
            border: '5px solid #9ec2e5',
            borderRadius: '4px',
          }}
        >
          <Typography
            component="h5"
            variant="h5"
            style={{ textAlign: 'center' }}
          >
            Additional Times
          </Typography>
          {secondaryIframeId && (
            <GenericScheduleEmbed
              iframeId={secondaryIframeId}
              iframePage={secondaryIframePage}
              email={decodedEmail}
              name={decodedName}
              lastName={decodedLastName}
              phone={decodedMobile}
            />
          )}
        </div>
      </div>
      <Box mb={2} />
    </div>
  );
};

export default Time2Book2;
