export const universalAnalyticsCovid = () => {
  var head = document.head;
  var script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = "https://175117.t.hyros.com/v1/lst/universal-script?ph=7440e0d779a6f02ffa04de98c67aca7736b309bf3148e971e360ba0e1a82b9eb&tag=!clicked";
  head.appendChild(script);

}

export const tikTokPixelCovid = () => {
  !function (w, d, t) {
    w.TiktokAnalyticsObject=t;
    var ttq=w[t]=w[t]||[];
    ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"];
    ttq.setAndDefer=function(t,e){
      t[e]=function(){
        t.push([e].concat(Array.prototype.slice.call(arguments,0)));
      };
    };
    for(var i=0;i<ttq.methods.length;i++){
      ttq.setAndDefer(ttq,ttq.methods[i]);
    }
    ttq.instance=function(t){
      for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++){
        ttq.setAndDefer(e,ttq.methods[n]);
      }
      return e;
    };
    ttq.load=function(e,n){
      var i="https://analytics.tiktok.com/i18n/pixel/events.js";
      ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};
      var o=document.createElement("script");
      o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;
      var a=document.getElementsByTagName("script")[0];
      a.parentNode.insertBefore(o,a);
    };
    ttq.load('C8JO6568DFHI6VRR9290');
    ttq.page();
  }(window, document, 'ttq');
}

export const gtagCovid = () => {
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'G-E1CTXJQDVC');
}

export const adrollCovid = () => {
  let adroll_adv_id;
  adroll_adv_id = "DOBAROGJXJEGVJWEG5RM47";
  let adroll_pix_id;
  adroll_pix_id = "LYD2TSTHNNF2NKXH6ML5SM";
  let adroll_version;
  adroll_version = "2.0";
  (function(w, d, e, o, a) {
    w.__adroll_loaded = true;
    w.adroll = w.adroll || [];
    w.adroll.f = [ 'setProperties', 'identify', 'track' ];
    var roundtripUrl = "https://s.adroll.com/j/" + adroll_adv_id + "/roundtrip.js";
    for (a = 0; a < w.adroll.f.length; a++) {
      w.adroll[w.adroll.f[a]] = w.adroll[w.adroll.f[a]] || (function(n) {
        return function() {
          w.adroll.push([ n, arguments ]);
        };
      })(w.adroll.f[a]);
    }
    e = d.createElement('script');
    o = d.getElementsByTagName('script')[0];
    e.async = 1;
    e.src = roundtripUrl;
    o.parentNode.insertBefore(e, o);
    w.adroll.track("pageView"); // move the call to track() after the script is loaded
  })(window, document);
}
